@mixin media-query-phone() {
  @media screen and (min-width: 320px) and (max-width: 600px - 1) {
    @content;
  }
}

@mixin media-query-tablet() {
  @media screen and (min-width: 600px) and (max-width: 960px - 1) {
    @content;
  }
}

@mixin media-query-tablet-and-phone() {
  @media screen and (max-width: 960px - 1) {
    @content;
  }
}

#app {
  .container {
    background-image: url("../img/hero-cropped.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;

    &__logo {
      padding-top: 30px;
      padding-left: 40px;
      font-family: "Bello", "Roboto", serif;
      font-size: 24px;
      height: 70px;
      text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;

      &__image {
        padding-right: 5px;
        border-radius: 50%;
        height: 100%;
      }
    }

    &__body {
      flex-grow: 1;
      padding-right: 40px;

      @include media-query-tablet-and-phone() {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        opacity: 0.9;
      }

      @include media-query-tablet() {
        align-items: center;
      }

      &__card {
        max-width: 600px;
        padding: 20px 30px;

        @include media-query-tablet-and-phone() {
          padding: 10px;
        }

        @include media-query-tablet() {
          max-height: 280px;
        }

        &__h1 {
          @include media-query-tablet-and-phone() {
            margin: 5px;
          }
        }

        &__p {
          @include media-query-tablet-and-phone() {
            margin-bottom: 5px;
          }
        }

        &__button {
          opacity: 1;
          background-color: rgb(0, 132, 137);
          color: #ffffff;
          margin-top: 15px;
        }
      }
    }
  }
}