body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#app .container {
  background-image: url(../../static/media/hero-cropped.7fd83a75.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh; }
  #app .container__logo {
    padding-top: 30px;
    padding-left: 40px;
    font-family: "Bello", "Roboto", serif;
    font-size: 24px;
    height: 70px;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff; }
    #app .container__logo__image {
      padding-right: 5px;
      border-radius: 50%;
      height: 100%; }
  #app .container__body {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding-right: 40px; }
    @media screen and (max-width: 959px) {
      #app .container__body {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        opacity: 0.9; } }
    @media screen and (min-width: 600px) and (max-width: 959px) {
      #app .container__body {
        -webkit-align-items: center;
                align-items: center; } }
    #app .container__body__card {
      max-width: 600px;
      padding: 20px 30px; }
      @media screen and (max-width: 959px) {
        #app .container__body__card {
          padding: 10px; } }
      @media screen and (min-width: 600px) and (max-width: 959px) {
        #app .container__body__card {
          max-height: 280px; } }
      @media screen and (max-width: 959px) {
        #app .container__body__card__h1 {
          margin: 5px; } }
      @media screen and (max-width: 959px) {
        #app .container__body__card__p {
          margin-bottom: 5px; } }
      #app .container__body__card__button {
        opacity: 1;
        background-color: #008489;
        color: #ffffff;
        margin-top: 15px; }

